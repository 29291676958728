.banner-promocional img {
    width: 100%;
    max-height: 145px;
}

.cpf.input-group input {
    padding: 23px 10px;
    border-radius: 15px;
}

.cpf.input-group .input-group-append button {
    border-radius: 0px 15px 15px 0px;
    padding: 6px 25px;
}

#banner-boas-vindas {
    width: calc(100% - 80px);
}

#ramo1 {
    position: absolute;
    left: -120px;
    top: 140px;
}


#ramo3 {
    position: absolute;
    right: -90px;
    top: 180px;
}


#banner-arvore {
    margin: 20px auto;
    display: block;
    width: 200px;

}

#enfeite1{
    display: block;
    max-width: 100%;
    margin: 40px 0px 10px 0px;
}


#enfeite3{
    display: block;
    margin: 0px auto;
}

@media (max-width: 992px) {
    #banner-arvore {
        
    }


}