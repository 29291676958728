// change the theme
@import url('https://fonts.cdnfonts.com/css/open-sans');

$primary: #59b88e;
$success: #066c37;
$danger: #f06650;
$body-color: #54412f;

$theme-colors: (
  "primary": $primary,
  "danger": $danger
);

$link-color: #ba54f5 !default;

$font-family-sans-serif: "Open Sans", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif !default;
$font-family-serif: Georgia, "Times New Roman", Times, serif !default;
$font-family-monospace: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$font-family-base: $font-family-sans-serif !default;

$border-color: #342619;
$input-group-addon-color: $border-color;
$input-group-addon-border-color: $border-color;
$input-border-color: $border-color;

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';

.requerido:after,
.required:after {
  content: '*';
  color: $danger;
  vertical-align: bottom;
  margin-left: 2px;
  line-height: 14px;
}

[aria-invalid="true"] {
  color: $danger;
}

.btn {
  border-radius: 6px;
  letter-spacing: 3px;
  font-weight: bold;
  font-size: 22px;
}

.btn-primary {
  color: #fff !important;
}

.btn-primary:hover,
.btn-primary:visited,
.btn-primary:focus,
.btn-primary:active {
  color: $body-color;
}

#root {
  position: relative;
  overflow: hidden;
  min-height: 500px;
}