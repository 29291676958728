.card {
    width: 350px;
}

.precoOriginal {
    text-decoration: line-through;
    font-size: 16px;
}

.precoFinal {
    font-size: 24px;
}

.precoInfoContainer {
    line-height: 1;
}

.percentualDesconto {
    position: absolute;
    top: 0px;
    right: 0px;
    color: white;
    padding: 5px;
    border-radius: 0px 4px 0px 4px;
}

.precoInfoContainer {
    display: block;
}

.carousel.carousel-slider {
    height: 348px;

}

.vitrine-container .carousel-root {
    width: 348px;
}

.vitrine-container .img-container {
    width: 348px;
    overflow: hidden;
}

.vitrine-container .img-container .img {
    width: 348px;
    height: 348px;
    overflow: hidden;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

@media (max-width: 576px) {

    .card {
        width: 140px;

    }

    .carousel.carousel-slider {
        height: 138px;

    }

    .vitrine-container .carousel-root {
        width: 138px;
    }

    .vitrine-container .img-container {
        width: 138px;
    }

    .vitrine-container .img-container .img {
        width: 138px;
        height: 138px;
    }

    .card-body {
        font-size: 11px;
    }

    .card-title {
        font-size: 16px;
    }

    .precoOriginal {
        font-size: 12px;
    }

    .precoFinal {
        font-size: 14px;
    }

    .card-body button {
        font-size: 11px;
        letter-spacing: normal;
    }
}



@media (min-width: 992px) {

    .card {
        width: 210px;

    }

    .carousel.carousel-slider {
        height: 208px;

    }

    .vitrine-container .carousel-root {
        width: 208px;
    }

    .vitrine-container .img-container {
        width: 208px;
    }

    .vitrine-container .img-container .img {
        width: 208px;
        height: 208px;
    }

    .card-body {
        font-size: 12px;
    }

    .card-title {
        font-size: 18px;
    }

    .precoOriginal {
        font-size: 14px;
    }

    .precoFinal {
        font-size: 16px;
    }

    .card-body button {
        font-size: 12px;
        letter-spacing: normal;
    }
}