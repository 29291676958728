#modelo-impressao {
    color: #000 !important;
    font-size: 20px;
    text-transform: uppercase;
}

#modelo-impressao tr {
    margin: 0px -5px;
}

#modelo-impressao td,
#modelo-impressao th {
    padding: 1px 5px;
}