#banner-ofertas-exclusivas {
    display: block;
    margin: 0px auto;
}

#btn-ativar-todas {
    width: 260px;
}

#banner-ofertas-exclusivas-lg {
    display: inline-block;
    background-repeat: no-repeat;
    background-position: top right;
}

#banner-ofertas-exclusivas-lg>div {
    align-items: center;
}

#banner-ofertas-exclusivas-lg-container {
    display: flex;
    justify-content: center;
}

#banner-ofertas-exclusivas-lg-container #btn-ativar-todas {
    margin-right: 80px !important;
}

#ramo2 {
    position: absolute;
    right: -180px;
    top: 20px;
}