nav {
    height: 100px;
    padding: 0px 50px !important;
}

#logo-natural-terra {
    height: 42px;
    margin-left: 30px;
}

#logo-hortifruti {
    height: 70px;
}

.navbar-brand {
    z-index: 1;
}

#logo-ln-container {
    position: absolute;
    width: 200px;
    right: 50px;
    top: -55px;
}

#logo-ln-container img {
    max-width: 100%;
    height: auto;
}

@media (max-width: 575.98px) {
    nav {
        height: 100px;
        padding: 0px 50px !important;
    }

    #logo-ln-container {
        right: 26px;
        top: -16px;
        width: 150px;
    }

    #logo-natural-terra {

        height: 42px;
        margin-left: 10px;
    }

    #logo-hortifruti {
        margin-left: -30px;
        height: 70px;
    }

}